$(window).ready(function() {

    function dropdownIn() {
        // dropdown-menu addclass _hover
        $(this).parents('.dropdown-menu').addClass('_hover');

        // add overlay
        $(this).parents('.dropdown-menu').prepend( 
            $(`<div class="modal-overlay"></div>` )
        );

        // dropdown-menu__trigger add class _active
        $(this).parents('.dropdown-menu').find('.dropdown-menu__trigger').addClass('_active');

        // dropdown-menu__trigger add class _active
        $(this).parents('.dropdown-menu').find('.dropdown-menu__trigger').addClass('_active');

        // show dropdown item
        $(this).parents('.dropdown-menu').find('.dropdown-menu__item').addClass('_show');
    }

    function dropdownOut() {
        // dropdown-menu removeclass _hover
        $(this).parents('.dropdown-menu').removeClass('_hover');

        // dropdown-menu__trigger remove class _active
        $(this).parents('.dropdown-menu').find('.dropdown-menu__trigger').removeClass('_active');

        // hide dropdown item
        $(this).parents('.dropdown-menu').find('.dropdown-menu__item').removeClass('_show');

        // remove overlay
        $(this).parents('.dropdown-menu').find('.modal-overlay').remove();
    }

    // On Hover
    $('.dropdown-menu--hover .dropdown-menu__trigger, .dropdown-menu--hover .dropdown-menu__item').mouseover(dropdownIn);
    $('.dropdown-menu--hover .dropdown-menu__trigger, .dropdown-menu--hover .dropdown-menu__item').mouseout(dropdownOut);

    // // On Click
    $('.dropdown-menu--click .dropdown-menu__trigger').click(dropdownIn);

    $(document).click(function(e) {
    // toggle function dropdownIn and dropdownOut on click
        if ($(e.target).parents('.dropdown-menu').length === 0) {
            $('.dropdown-menu--click .dropdown-menu__trigger, .dropdown-menu--click .dropdown-menu__item').each(dropdownOut);
        }
    });

    $('.dropdown-menu__close').click(dropdownOut);


    // On Focus
    $('.dropdown-menu--focus .dropdown-menu__trigger').focusin(dropdownIn)
    $('.dropdown-menu--focus .dropdown-menu__trigger').focusin(function() {
        $(this).parents('.navbar__search').addClass('_show');
    })
    
    $('.dropdown-menu--focus').on("click", ".modal-overlay", function(){
        $(this).parents('.navbar__search').removeClass('_show');
    });
    $('.dropdown-menu--focus').on("click", ".modal-overlay", dropdownOut);
});


function removeHistory() {
    // remove overlay
    // $(this).parents('.dropdown-menu').find('.modal-overlay').remove();
    
    // $('.dropdown-menu--focus .dropdown-menu__trigger').focus();
    $(this).parents('.search-history__item').remove();

    if ( $('.search-history ul').children().length < 1 ) {
        $('.search-history').remove();
    }
}
$('.search-history__remove').click(removeHistory);